<template>
  <layout-main>
    <div class="survey">
      <!-- Header -->

      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>Mål #{{ testimony.id }}</h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/cases" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/cases" class="breadcrumbs-link"> Mål</router-link>
              <!-- <span class="separator-dot"></span>
              <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.active') }}</router-link> -->
            </div>
          </div>
          <div class="subheader-right" v-if="!isLoading">
            <v-guard :permissions="['court-case-delete']">
              <div class="item" v-if="testimony.is_editable">
                <button @click="deleteCase" class="btn btn-danger">{{ $t('labels.delete-case') }}  <i class="fas fa-trash" style="margin-left: 10px;"></i></button>
              </div>
            </v-guard>
            <!-- <v-guard :permissions="['ticket-new-from-ticket']">
              <div class="item">
                <button @click="newTicketFromTicket" v-if="ticket.is_anonymized !== 1" class="btn btn-sm btn-light">
                  <i class="fas fa-plus"></i> {{ $t('labels.ticket-same-person') }}
                </button>
              </div>
            </v-guard>
            <v-guard :permissions="['ticket-mail-office']">
              <div class="item">
                <button @click="sendTicket" v-if="ticket.is_anonymized !== 1" class="btn btn-sm btn-light">
                  <i class="fas fa-envelope"></i> {{ $t('labels.communicate-remind-on-call') }}
                </button>
              </div>
            </v-guard>

            <v-guard :permissions="['ticket-mail-notification']" v-if="ticket.users.length !== 0">
              <div class="item">
                <button @click="sendTicketNotification" v-if="ticket.is_anonymized !== 1 && ticket.users.length !== 0"
                        class="btn btn-sm btn-light">
                  <i class="fas fa-envelope"></i> {{ $t('labels.notify-administrator') }}
                </button>
              </div>
            </v-guard>

            <v-guard :permissions="['ticket-mail-office']">
              <div class="item">
                <button @click="sendTicketUpdateNotification" v-if="ticket.is_anonymized !== 1"
                        class="btn btn-sm btn-light">
                  <i class="fas fa-envelope"></i> {{ $t('labels.update-on-call') }}
                </button>
              </div>
            </v-guard>

            <div class="item">
              <div
                  v-if="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-field-status-edit')"
                  class="verified label clickable"
                  :style="{ borderColor: ticket.ticketstatus.color + '1A', backgroundColor: ticket.ticketstatus.color + '1A' }"
                  @click="editField('ticketstatus_id', 'getStatuses', false, 'label', 'Status')"
              >
                <label :style="{ color: ticket.ticketstatus.color }">{{ $to(ticket.ticketstatus,'label') }}</label
                ><i :style="{ color: ticket.ticketstatus.color }" :class="ticket.ticketstatus.icon" class="ml-2"></i>
              </div>
              <div
                  v-if="ticket.is_anonymized === 1 || !$store.getters.permissions.includes('ticket-field-status-edit')"
                  class="verified label"
                  :style="{ borderColor: ticket.ticketstatus.color + '1A', backgroundColor: ticket.ticketstatus.color + '1A' }"
              >
                <label :style="{ color: ticket.ticketstatus.color }">{{ ticket.ticketstatus.label }}</label
                ><i :style="{ color: ticket.ticketstatus.color }" :class="ticket.ticketstatus.icon" class="ml-2"></i>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- Header -->

      <!-- <div class="anonymized-ticket" v-if="!isLoading">{{ $t('labels.anonymized-ticket') }}
      </div> -->

      <div class="widget overview" v-if="!isLoading">
        <div class="widget-body pr-0 pl-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xs-12 col-md-3">
                <div class="overview-main">
                  <div class="kt-profile__main-info">
                    <div class="overview-main-title">#{{testimony.id}} </div>
                    <div class="overview-main-subtitle">
											<!-- <span> -->
												<!-- <span>{{ $to(ticket.gender,'label') }}</span> </span>, -->
                      <span class="d-inline">
												<!-- <span v-if="ticket.age">{{ $to(ticket.age,'label') }}</span>
												<span v-if="!ticket.age">{{ $t('labels.missing-data') }}</span> -->
											</span>
                    </div>

                    <!-- Typ: Brottsmål -->
                    <!-- Brottstyp: Brottsmål -->
                    <!-- Mål typ: Brottsmål -->
                    <!-- Antal brottsoffer: Brottsmål -->
                    <!-- Tingsrätt: Brottsmål -->
                    <!-- Vittnesstödjare: Brottsmål -->
                    <!-- Datum: Brottsmål -->

                    <!-- <div v-if="ticket.is_anonymized === 1">
                      <div class="label label-xs text-uppercase mt-3 fw-600" :class="'prio-' + ticket.priority.label">
                        {{ ticket.priority.label }}
                        {{ $t('labels.prio') }}
                      </div>
                      <div
                          class="label label-xs text-uppercase mt-3 fw-600 ml-3"
                          :class="{ ' label-grey': !isRisk, 'label-red': isRisk }"
                      >
                        <i class="fas fa-exclamation-triangle"></i>
                      </div>
                    </div>
                    <div v-if="ticket.is_anonymized !== 1">
                      <v-guard :permissions="['ticket-field-priority-edit']">
                        <div
                            @click="editField('priority_id', 'getPriority', false, 'label', 'Prioritet')"
                            class="label label-xs text-uppercase mt-3 fw-600 cursor-pointer"
                            :class="'prio-' + ticket.priority.label"
                        >
                          {{ $to(ticket.priority,'label') }} {{ $t('labels.prio') }}
                        </div>
                      </v-guard>
                      <v-guard :permissions="['ticket-field-risk-edit']">
                        <div
                            v-tooltip="isRisk ? this.$t('labels.deselect-as-high-risk') : this.$t('labels.select-as-high-risk')"
                            class="label clickable label-xs text-uppercase mt-3 fw-600 ml-3"
                            @click="toggleRisk()"
                            :class="{ ' label-grey': !isRisk, 'label-red': isRisk }"
                        >
                          <i class="fas fa-exclamation-triangle"></i>
                        </div>
                      </v-guard>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>Tingsrätt</label>
                    <span v-if="testimony.court">{{ $to(testimony.court,'label') }}</span>
                    <span v-if="!testimony.court">-</span>
                    <!-- <span class="d-inline" v-for="cr in ticket.crimerelation" :key="cr.id">{{ $to(cr,'label') }}, </span> -->
                  </div>

                  <!-- <div class="list-item">
                    <label>Kontakt med stödsökanden</label>
                    <span v-if="testimony.court_contact_types">{{ $to(testimony.court_contact_types,'label') }}</span>
                    <span v-if="!testimony.court_contact_types">-</span>
                  </div> -->
                </div>
              </div>
              <div class="col-xs-12 col-md">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>Typ av mål</label>
                    <span v-if="testimony.testimony_type">{{ $to(testimony.testimony_type,'label') }}</span>
                    <span v-if="!testimony.testimony_type">-</span>
                  </div>
                  <div class="list-item" v-if="isCrimeOrCivilCase('Brottmål')">
                    <label>{{ $t('labels.crime') }}</label>
                    <span v-if="testimony.crime">{{ $to(testimony.crime,'label') }}</span>
                    <span v-if="!testimony.crime">-</span>
                  </div>
                  <div class="list-item" v-if="isCrimeOrCivilCase('Civilmål')">
                    <label>Civilmålstyp</label>
                    <span v-if="testimony.testimony_other_type">{{testimony.testimony_other_type.label}}</span>
                    <span v-if="!testimony.testimony_other_type">-</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.created-by') }}</label>
                    <span v-if="testimony.user.name">{{ testimony.user.name }}</span>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>{{ $t('labels.local-office') }}</label>
                    <span>{{ testimony.local_office.label }}</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.duty') }}</label>
                    <span v-if="testimony.office">{{ testimony.office.label }}</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.administrator') }}</label>
                    <span v-if="testimony.users">{{ administrators }}</span>
                  </div>
                </div>
              </div>
              <div class="col-auto col-md-2">
                <div class="list overview-last">
                  <div class="list-item">
                    <label>Datum inlagt</label>
                    <span>{{ testimony.created_date_formatted }}</span>
                  </div>
                  <div class="list-item">
                    <label>Rättegångsdatum <info-box :field="'Ärendeformuläret - flik Geografi, rubrik Rättegångsdatum'" class="inside-list-item" /></label>
                    
                    <date-range-picker v-if="testimony.is_editable"
                      opens="left"
                      v-model="date"
                      :auto-apply="false"
                      :locale-data="locale"
                      :ranges="ranges"
                      date-util="moment"
                      :show-dropdowns="true"
                      :single-date-picker="true"
                      :time-picker="true"
                      control-container-class="form-control reportrange-text custom"
                      @update="setDate"
                  >

                    <template #input="picker">
                        <div class="date" >
                            <div class="picker" v-if="picker.startDate == null">Välj datum</div>
                            <div class="picker" v-if="picker.startDate != null">{{formatDate}}</div>
                            <span class="arrow"></span>
                        </div>
                    </template>

                  </date-range-picker>
                  <span v-if="!testimony.is_editable">{{ date.startDate }}</span>
                  </div>
                  <div class="list-item">
                    <label>Antal inmatade personer</label>
                    <span>{{ persons }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-footer with-tabs" v-if="showDetails">
          <ul class="nav nav-tabs nav-tabs-line" role="tablist">
            <!-- <v-guard :permissions="['ticket-section-person-view']"> -->
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 1 }">
                <div class="nav-link" @click="setShowDetailSection(1)">Om målet</div>
              </li>
            <!-- </v-guard> -->
            <!-- <v-guard :permissions="['ticket-section-information-view']"> -->
              <!-- <li class="nav-item section" :class="{ 'current-section': showDetailSection === 2 }">
                <div class="nav-link" @click="setShowDetailSection(2)">{{ $t('labels.about-ticket') }}</div>
              </li> -->
            <!-- </v-guard> -->
            <li class="nav-item ml-auto mr-0">
              <div class="nav-link" @click="toggleDetails()">{{ $t('labels.close') }} <i class="ml-2 fas fa-angle-up"></i></div>
            </li>
          </ul>
        </div>



        <!-- <div class="widget-body bt-1-dark pr-0 pl-3" v-if="showDetails && showDetailSection === 1">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.name-gender-age') }}</h5>

              </div>
              <div class="col-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.name-gender-age') }}</h5>

              </div>
            </div>
          </div>
        </div> -->

        <div class="widget-body bt-1 pr-0 pl-3" v-if="showDetails && showDetailSection === 1">
          <div class="container-fluid">
            <div class="row">


              <div class="col-7 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">Uppgifter om målet</h5>

                <select-field
                  api="updateTestimonyField"
                  :clearable="false"
                  :model="testimony"
                  :multiple="false"
                  :options="testimonyFieldOptions.testimony_types"
                  db_field="testimony_type_id"
                  field="testimony_type"
                  label="Typ av mål"
                  :optionLabel="$label()"
                  :infoBox="''"
                  @fieldSaved="getTestimonyInit"
                  :editable="testimony.is_editable"
                ></select-field>

                <select-field
                  v-if="isCrimeOrCivilCase('Brottmål')"
                  api="updateTestimonyField"
                  :clearable="false"
                  :model="testimony"
                  :multiple="false"
                  :options="testimonyFieldOptions.crime"
                  db_field="crime_id"
                  field="crime"
                  label="Brottstyp"
                  :optionLabel="$label()"
                  :infoBox="'Domstol - brottstyp'"
                  @fieldSaved="getTestimonyInit"
                  :editable="testimony.is_editable"
                ></select-field>

                <select-field
                  v-if="isCrimeOrCivilCase('Civilmål')"
                  api="updateTestimonyField"
                  :clearable="false"
                  :model="testimony"
                  :multiple="false"
                  :options="testimonyFieldOptions.testimony_other_types"
                  db_field="testimony_other_type_id"
                  field="testimony_other_type"
                  label="Civilmålstyp"
                  :optionLabel="$label()"
                  :infoBox="''"
                  @fieldSaved="getTestimonyInit"
                  :editable="testimony.is_editable"
                ></select-field>

                <select-field
                  api="updateTestimonyField"
                  :clearable="false"
                  :model="testimony"
                  :multiple="false"
                  :options="testimonyFieldOptions.external_cases"
                  db_field="external_case_id"
                  field="testimony_external_case"
                  label="Externt mål"
                  :optionLabel="$label()"
                  :infoBox="'Domstol - Externt mål'"
                  @fieldSaved="getTestimonyInit"
                  :editable="testimony.is_editable"
                ></select-field>

                <select-field
                  api="updateTestimonyField"
                  :clearable="false"
                  :model="testimony"
                  :multiple="false"
                  :options="testimonyFieldOptions.testimony_security_room"
                  db_field="testimony_security_room_id"
                  field="testimony_security_room"
                  label="Säkerhetssal"
                  :optionLabel="$label()"
                  :infoBox="'Domstol - säkerhetssal'"
                  :editable="testimony.is_editable"
                ></select-field>

                <select-field
                  api="updateTestimonyField"
                  :clearable="true"
                  :model="testimony"
                  :multiple="false"
                  :options="testimonyFieldOptions.special_event"
                  db_field="special_events_id"
                  field="special_events"
                  label="Särskilda händelser"
                  :optionLabel="$label()"
                  :infoBox="'Ärendeformuläret - flik Om ärendet, Särskilda händelser'"
                  :editable="testimony.is_editable"
                ></select-field>

                <select-field
                  api="updateTestimonyField"
                  :clearable="true"
                  :model="testimony"
                  :multiple="false"
                  :options="testimonyFieldOptions.crime_relation"
                  db_field="crime_relation_id"
                  field="crime_relation"
                  label="Särskilda omständigheter"
                  :optionLabel="$label()"
                  :infoBox="'Ärendeformuläret - flik Om ärendet, Särskilda omständigheter'"
                  :editable="testimony.is_editable"
                ></select-field>

              </div>
              <!-- <div class="col-4 br-1"> -->
                <!-- <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.contact-information') }}</h5> -->


              <!-- </div> -->
            </div>
          </div>
        </div>



        <div class="widget-footer with-tabs" v-if="!showDetails">
          <ul class="nav nav-tabs nav-tabs-line" role="tablist">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'cases', params: { id: testimony.id } }">{{ $t('labels.overview') }}</router-link>
            </li>
            <!-- <v-guard
                :permissions="[
								'ticket-section-person-view',
								'ticket-section-information-view',
								'ticket-section-primary-crime-view',
								'ticket-section-secondary-crime-view',
								'ticket-section-geography-view',
								'ticket-section-consent-view',
								'ticket-section-police-view',
							]"
            > -->
              <li class="nav-item ml-auto mr-0" :class="{'disabled':!isLoadingOptions}">
                <div class="nav-link" @click="toggleDetails()" v-if="showDetails">{{ $t('labels.close') }} <i
                    class="ml-2 fas fa-angle-up"></i>
                </div>
                <div class="nav-link" @click="toggleDetails()" v-if="!showDetails">
                  Redigera mål <i class="ml-2 fas fa-angle-down"></i>
                </div>
              </li>
            <!-- </v-guard> -->
          </ul>
        </div>
      </div>

      <div style="position: relative;">
        <transition name="slide-right">
          <router-view
              @get-witness="getTestimonyInit"
              class="child-view"
              v-if="!isLoading"
              :render="reRender"
              :isAddingPerson.sync="isAddingPerson"
              :isLoadingOptions="isLoadingOptions"
              :isUpdating="isUpdating"
              :testimony="testimony"
              :testimonyFieldOptions="testimonyFieldOptions"
              :key="testimony.id"
              :override.sync="override"
          ></router-view>
        </transition>
      </div>
    </div>
  </layout-main>
</template>

<script>
import BojAPI from '@/api/boj';
import LayoutMain from '@/views/Layouts/Main';
import Crime from '@/components/ticket/Crime';
import SelectLocalOffice from '@/components/ticket/SelectLocalOffice';
import SelectField from '@/components/ticket/fields/SelectField';
import TextField from '@/components/ticket/fields/TextField';
import Checkbox from '@/components/ticket/fields/Checkbox';
import MessageFromAPI from "@/components/ticket/MessageFromAPI";
import InfoBox from '@/components/InfoBox';
import _ from 'lodash';
import axios from 'axios'
import { Trans } from '@/plugins/Translation';TextField
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment';

export default {
  name: 'ticket-page',
  components: {
    LayoutMain,
    MessageFromAPI,
    Crime,
    SelectLocalOffice,
    SelectField,
    TextField,
    Checkbox,
    InfoBox,
    Trans,
    DateRangePicker
  },
  props: {
    id: Number,
  },
  data() {
    return {
      testimony: {},
      selectLocalOffice: false,
      confirmDelete: '',
      showDetails: false,
      showDetailSection: 1,
      reRender: false,
      isSaving: false,
      isLoading: true,
      isUpdating: false,
      isFetchingTestimony: false,
      showSidebar: false,
      testimonyFieldOptions: {},
      isAddingPerson: false,
      isLoadingOptions: false,
      override: false,
      locale: {
        direction: 'ltr',
        format: 'yyyy-mm-dd, HH:mm',
        separator: ' - ',
        applyLabel: 'Välj',
        cancelLabel: 'Avbryt',
        fromLabel: 'Från',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sön','Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
        monthNames: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
        firstDay: 1,
        todayLabel:'Idag'
      },
      date: {
        startDate: '-',
        endDate: ''
      },
      datePickerChanged: false
    };
  },

  watch: {
    testimony:{
      handler: function(value) {
        this.date.startDate = value.case_date ? moment(value.case_date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm"): ''
      },
      deep:true
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date.startDate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD, HH:mm") : 'Välj datum';
    },
    persons() {
      return this.testimony.persons.length;
    },
    administrators() {
      return this.testimony.users.length ? this.testimony.users.map(user => (user.name)).join(', ') : '-'
    },
    ranges() {

      return {
        Idag: [moment().toDate(), moment().toDate()],
        Igår: [
            moment().subtract(1, "days").toDate(),
            moment().subtract(1, "days").toDate()
        ],
        "Denna månad": [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate()
        ],
        "Förra månaden": [
            moment()
                .subtract(1, "month")
                .startOf("month").toDate(),
            moment()
                .subtract(1, "month")
                .endOf("month").toDate()
        ],
        "I år": [
            moment().startOf("year").toDate(),
            moment().endOf("year").toDate()
        ]
      }
    },
    crimeFieldEmpty() {
      if (this.testimony.is_editable && !this.testimony.crime_id && !this.testimony.testimony_other_type_id) {
        return false;
      }

      return true;
    }
  },
  beforeRouteEnter(to, from, next) {
    BojAPI.getTestimony(to.params.id)
      .then((response) => {
        next((vm) => {
          vm.setData(response.data)
        });
      })
      .catch((error) => {
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.override || this.crimeFieldEmpty && !this.isUpdating) {
        next()
    } else {

      if (this.isUpdating) {
        this.$confirm(
          {
          message: 'Sparar...',
          button: {
            yes: 'OK',
          }
        })

        next(false);
      } else {
        const selectedCaseType = this.testimonyFieldOptions.testimony_types.find(type => type.id == this.testimony.testimony_type.id);
        const self = this;
        this.$confirm(
          {
          message: 'Du måste välja ' + selectedCaseType.label.toLowerCase() + 'styp innan ärendet kan sparas.',
          button: {
            no: 'Redigera',
            yes: 'Radera målet',
          },
          async callback(confirm) {

            if (confirm) {
              try {
                await BojAPI.deleteTestimony(self.testimony.id);

                self.override = true;

                self.$notify({
                  group: 'foo',
                  text: self.$t('labels.case-deleted'),
                  type: 'success',
                  duration: 3000,
                });

                setTimeout(function () {
                  self.$router.push({name: 'cases'});
                }, 2000);

              } catch (error) {
                 self.$notify({
                  group: 'foo',
                  text: self.$t('labels.no-permission-deleting-case'),
                  type: 'warning',
                  duration: 3000,
                });
              }
            } else {
              if (!self.showDetails) {
                self.toggleDetails();
              }
            }
          }
        })

        next(false);
      }
    }
  },
  mounted() {
    this.getTestimonyOptions();
  },
  methods: {
    /**
     * Deletes a member
     *
     * Redirect to members-page after 2 seconds,
     * allowing the notification to be shown.
     */
    deleteCase() {
      if (confirm('Är du säker?')) {
        this.override = true;

        BojAPI.deleteTestimony(this.testimony.id)
          .then((response) => {
            let self = this;
            this.$notify({
              group: 'foo',
              text: this.$t('labels.case-deleted'),
              type: 'success',
              duration: 3000,
            });
            setTimeout(function () {
              self.$router.push({name: 'cases'});
            }, 2000);
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission-deleting-case'),
              type: 'warning',
              duration: 3000,
            });
          });
      }
    },
     setDate(value) {
        this.datePickerChanged = true;

        const field = 'case_date'
        const date = moment(value.startDate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
        this.date.startDate = date;

         BojAPI['updateTestimonyField'](this.testimony, field, date)
          .then((response) => {

            this.$notify({
              group: 'foo',
              text: this.$t('labels.changes-saved'),
              type: 'success',
              duration: 3000,
            });
            this.reRender = true;
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission'),
              type: 'warning',
              duration: 3000,
            });
          });


    },
    /**
     *
     * Load testimony options
     *
    */
    getTestimonyOptions() {
      this.isLoadingOptions = true

      BojAPI.getTestimonyOptions().then((response) => {
        this.testimonyFieldOptions = response.data;
        this.isLoadingOptions = false
      });
    },

    /**
     * Display Error Message
     */
    showError(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warning',
        duration: 3000,
      });
    },

    /**
     * Display Confirmation Message
     */
    showConfirmation(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'success',
        duration: 3000,
      });
    },

    /**
     * Display Notice Message
     */
    showNotice(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warn',
        duration: 3000,
      });
    },


    setShowDetailSection(section) {
      this.showDetailSection = section;
    },


    toggleDetails() {
      if (this.showDetails) {
        this.showDetails = !this.showDetails;
        this.setShowDetailSection(1);
      } else {
        this.showDetails = !this.showDetails;
      }
    },


    /**
     * Assign received data to local state.
     */
    async setData(payload) {
      this.testimony = payload.data;
      this.isLoading = false;

      return true
    },

    isCrimeOrCivilCase(type) {
      if (!this.testimony.testimony_type || this.isLoadingOptions) return false;

      let selectedType = this.testimonyFieldOptions.testimony_types.find(type => type.id == this.testimony.testimony_type.id);

      if (selectedType && selectedType.label == type) {
        return true;
      }

      return false
    },

    /**
     * Retrieve ticket from API.
     */
    getTestimonyInit() {
      this.isUpdating = true;
      this.getTestimony();
    },

    /**
     * Retrieve ticket from API.
     */
    getTestimony: _.debounce(function () {
      const axiosSource = axios.CancelToken.source();

      if (this.isFetchingTestimony) {
        this.isFetchingTestimony.cancel();
        this.isFetchingTestimony = false;
      }
      this.isFetchingTestimony = {cancel:axiosSource.cancel};

      BojAPI.getTestimony(this.id, {cancelToken: axiosSource.token}).then((response) => {
        this.testimony = response.data.data;
        this.isLoading = false;
        this.isFetchingTestimony = false;
        this.isAddingPerson = false;
        this.isUpdating = false;

      }).catch((error) => {
        if(axios.isCancel(error)) {
          return
        }

        this.showError(this.$t('labels.ticket-error'));
      });
    }, 1000),

  },
};
</script>
