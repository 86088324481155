<template>
	<div>
		<div class="sidebar" v-if="showSidebarInfobox">
			<div class="container-fluid">
				<div class="container p-4 d-flex flex-column">
					<div class="d-flex justify-content-between">
						<h3 class="mb-4 title">{{ title }}</h3>
						<i class="fas fa-times-circle" style="cursor: pointer" @click="toggleSidebarInfobox()" />
					</div>
					<p class="description" v-html="description"></p>
					<button class="btn btn-primary align-self-end" @click="toggleSidebarInfobox()">
						{{ $t('labels.close') }}
					</button>
				</div>
			</div>
		</div>
		<div class="overlay" v-if="showSidebarInfobox" @click="toggleSidebarInfobox()"></div>
		<span role="button" @click="toggleSidebarInfobox()"><slot name="icon"></slot></span>
	</div>
</template>

<script>
export default {
	name: 'InfoBoxSidebar',
	props: {
		field: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			showSidebarInfobox: false,
		};
	},
	computed: {
		infoBox: function () {
			return this.$store.getters.getInfoBox(this.field);
		},
		title: function () {
			return this.$to(this.infoBox, 'title');
		},
		description: function () {
			let text = this.$to(this.infoBox, 'description');
			return text.replace(/<a href/g, '<a target="_blank" href');
		},
	},
	methods: {
		toggleSidebarInfobox() {
			let element = document.querySelector('body');
			this.showSidebarInfobox ? element.classList.remove("overflow-hidden") : element.classList.add("overflow-hidden");
				
			this.showSidebarInfobox = !this.showSidebarInfobox;
		},
	},
};
</script>

<style scoped>
.title {
	font-size: 1.5rem !important;
	font-weight: 500 !important;
	line-height: 1.2 !important;
	color: #1a1a1a !important;
	font-family: Poppins !important;
	text-align: left !important;
	text-transform: none !important;
}

.description {
	white-space: pre-wrap !important;
	font-size: 1rem !important;
	font-weight: 500 !important;
	line-height: 1.5 !important;
	color: #1a1a1a !important;
	font-family: Poppins !important;
	text-align: left !important;
	text-transform: none !important;
}
</style>
