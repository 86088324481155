<template>
  <div class="form-group row">
    <label :for="field + '_field'" :class="{ 'col-sm-4': !fullWidth, 'col-sm-12': fullWidth }" class="col-form-label"
           v-if="label.length !== 0">{{
        label
      }}
      <info-box :field="infoBox" />
    </label>

    <div :class="label.length !== 0 && !fullWidth ? 'col-sm-8' : 'col-12'">
      <date-range-picker
        ref="picker"
        opens="center"
        v-model="model[field]"
        :auto-apply="false"
        :locale-data="locale"
        :ranges="ranges"
        date-util="moment"
        :show-dropdowns="true"
        :single-date-picker="true"
        :time-picker="true"
        control-container-class="form-control reportrange-text custom"
        @update="setDate"
      >
        <template #input="picker">
            <div class="date">
                <div class="picker" v-if="picker.startDate == null">{{optionLabel}}</div>
                <div class="picker" v-if="picker.startDate != null">{{formatDate}}</div>
                <span class="arrow"></span>
            </div>
        </template>

      </date-range-picker>
    </div>

  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment'
import InfoBox from '@/components/InfoBox';

export default {
  components: {
    DateRangePicker,
    InfoBox
  },
  props: {
    api: String,
    fullWidth: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    help: {
      type: String,
      default: '',
    },
    optionLabel: {
      type: String,
      default: 'Välj datum',
    },
    model: Object,
    field: String,
    infoBox: String,
    label: String,
    save: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      locale: {
        direction: 'ltr',
        format: 'yyyy-mm-dd, HH:mm',
        separator: ' - ',
        applyLabel: 'Välj',
        cancelLabel: 'Avbryt',
        fromLabel: 'Från',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sön','Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
        monthNames: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
        firstDay: 1,
        todayLabel:'Idag'
      },
      datePickerChanged: false
    }
  },
  computed: {
    formatDate() {
      return this.model[this.field].startDate ? moment(this.model[this.field].startDate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD, HH:mm") : this.optionLabel
    },
    ranges() {

      return {
        Idag: [moment().toDate(), moment().toDate()],
        Igår: [
            moment().subtract(1, "days").toDate(),
            moment().subtract(1, "days").toDate()
        ],
        "Denna månad": [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate()
        ],
        "Förra månaden": [
            moment()
                .subtract(1, "month")
                .startOf("month").toDate(),
            moment()
                .subtract(1, "month")
                .endOf("month").toDate()
        ],
        "I år": [
            moment().startOf("year").toDate(),
            moment().endOf("year").toDate()
        ]
      }
    },
  },
  methods: {
    setDate(value) {
      this.datePickerChanged = true;

      const date = moment(value.startDate).format("YYYY-MM-DD HH:mm");
      this.model[this.field].startDate = date;
      this.model[this.field].endDate = date;

      BojAPI[this.api](this.model, this.field, date)
      .then((response) => {
        this.$emit('fieldSaved');

        this.$notify({
          group: 'foo',
          text: this.$t('labels.changes-saved'),
          type: 'success',
          duration: 3000,
        });

      })
      .catch((error) => {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.no-permission'),
          type: 'warning',
          duration: 3000,
        });
      });
    },
  },
}
</script>

<style>

</style>
